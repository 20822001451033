<template>
	<div class="page" style='position: relative;'>


		<div class='container' style='padding-top: 10px;'>
			<div class="con">
				<div class="box1">
					<div class="title">请将身份证放在读卡器上</div>
					<img id="view1" src="../assets/images/dkq.jpg" alt="主摄像头">
				</div>
				<div class="box2">
					<div class="btn" @click="fileScan">
						{{ msg }}
					</div>
				</div>
			</div>




			<el-dialog title="参数选择" :visible.sync="dialogVisible" width="26%" :before-close="handleClose">
				<div class="dialogCon">
					<div style='text-align: center;'>
						<el-image style="width: 100px; height: 100px; margin:0 auto" :src="img"
							:preview-src-list="srcList">
						</el-image>
					</div>

					<div class="box">

						<el-form :model="wxData" :rules="rules" ref="wxData" label-width="100px" class="demo-wxData">
							<el-form-item label="打印数量" prop="name">
								<el-input-number v-model="wxData.print_number" :min="1" @change="sumMoney()"
									label="描述文字">
								</el-input-number>
							</el-form-item>

							<el-form-item label="打印类型">
								<el-radio-group v-model="wxData.paper_type" @change="sumMoney()">
									<el-radio label="2" border>彩色</el-radio>
									<el-radio label="1" border>黑白</el-radio>



								</el-radio-group>
							</el-form-item>



							<el-form-item label="是否群打印" v-if="is_enterprise == 1">
								<el-switch v-model="isGroup"></el-switch>
							</el-form-item>

							<div style='height:60px'>
								<el-form-item label="群打印成员" v-if="isGroup">
									<el-checkbox-group v-model="ids">
										<el-checkbox :label="item.id" name="type" v-for="(item,index) in accList">
											{{ item.real_name }}
										</el-checkbox>
									</el-checkbox-group>
								</el-form-item>
							</div>


							<el-form-item>
								<el-button :loading="previewLoading" type="primary" @click="previewPic()">
									打印预览</el-button>
							</el-form-item>






							<el-form-item label="价格">
								<p>¥ {{ price }}</p>
							</el-form-item>


							<el-form-item v-if="this.$store.state.userinfo">
								<el-button type="success" @click="pay('wx')">微信支付</el-button>
								<el-button type="success" @click="pay('ye',1)">余额支付</el-button>
								<el-button type="success" @click="pay('ye',2)" v-if="is_enterprise == 1">企业支付
								</el-button>
							</el-form-item>

							<el-form-item v-else>
								<el-button type="success" @click="ykpay()">微信支付</el-button>
							</el-form-item>

						</el-form>



						<vue-qr :text="code" :size="200"
							style="margin:0 auto; width:200px; height:200px; margin-top:30px" v-if="iscode"></vue-qr>

					</div>

				</div>

			</el-dialog>

			<el-dialog title="微信扫码支付" :visible.sync="showc" width="20%" :before-close="wxclose"
				style="text-align: center;">

				<vue-qr :text="code" :size="200" style="margin:0 auto;width:200px; height:200px"></vue-qr>

			</el-dialog>


			<el-dialog :visible.sync="printOk" width="30%">
				<div class="info" style="text-align: center;">
					<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
					<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

					<div v-if="$store.state.userinfo">
						<p style="color:red; font-weight: bold; font-size: 16px; margin-bottom: 20px;">
							为确保您的信息安全，打印完成后记得退出</p>
						<p>
							<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
						</p>
						<p>
							<el-button type="primary" style="width:80%; margin:30px 0" @click="$router.go(0)">继续打印
							</el-button>
						</p>
					</div>
				</div>
			</el-dialog>


		</div>


		<Print ref="print"></Print>





		<div class="sfz"
			style="width:210mm; height:297mm; background-color:#fff; text-align: center; position:absolute; top:-9999px"
			id="sfz" ref="sfz">
			<div>
				<img :src="sfz1" alt="" style="margin:100px 0;">
			</div>
			<div>
				<img :src="sfz2" alt="">
			</div>
		</div>


		<div class="mask" style="width:100vw; height:100vh; position: absolute; top:-115px;" v-show="loading"
			v-loading="loading" element-loading-text="正在打印" element-loading-spinner="el-icon-loading"
			element-loading-background="rgba(0, 0, 0, 0.5)" customClass="myloading"></div>

	</div>

</template>

<script>
	import {
		getLodop
	} from '../utils/LodopFuncs'

	import vueQr from 'vue-qr'

	import Print from "@/components/Print.vue"

	import html2canvas from 'html2canvas'
	import axios from 'axios'
	export default {
		components: {
			vueQr,
			Print
		},
		data() {
			return {
				pic: require('@/assets/images/code2.png'),
				pics: [],
				img: '',
				sfz1: '',
				sfz2: '',
				msg: '身份证读取',
				dialogVisible: false,
				dialogVisible2: false,
				file: '',
				file_hb: '',
				flag: true,
				code: '',
				timer: null,
				jobCode: '1', //job码
				printStatus: 1, //打印状态
				loading: false,
				printOk: false,
				isGroup: false,
				isGroup: false,
				rules: {},
				wxData: {
					type: "",
					task_type: '8',
					paper_type: '1',
					print_type: '2', //A4
					print_number: '1',
					print_count: '1',
					print_file: '',
					print_hb_file: '',
					print_ctype: '1',
					money_type: '',
					task_number:""
				},

				sumMoneyData: {
					type: '',
					page: '',
					number: '',
					ctype: '1',
					paper_type: '1'
				},
				srcList: [],
				price: '',
				showc: false,
				code: '',
				is_enterprise: false,
				ids: [],

				previewLoading: false
			}
		},
		created() {

			this.common.voice('请将身份证放在读卡器上')

			if (this.$store.state.userinfo) {
				if (this.$store.state.userinfo.is_enterprise == 1) {
					this.is_enterprise = true
					this.AccountList();
				}

			}

		},

		methods: {

			//语音

			voice(text) {
				console.log(text)
				let useVoiceText = text;
				let to_speak = new SpeechSynthesisUtterance(useVoiceText);
				window.speechSynthesis.speak(to_speak)
			},




			async fileScan() {
				let data = {
					"filepath": "base64",
					"rotate": "0",
					"cutpage": "0",
					"camidx": "0",
					"ColorMode": "0",
					"quality": "3",
					"notoken": 'true',
					"deskew": "1",
					"deskewval": "10"
				}



				let {
					data: res
				} = await axios.get("http://localhost:8989/api/ReadMsg?cardImg=1&waitTime=3");

				if (res.code != 0) {

					return this.$message.error(res.retmsg)
				}

				console.log('身份证信息', res)

				//return false;


				let data2 = {
					'imageOne': res.frontImg,
					'imageTwo': res.backImg,
				};

				this.sfz1 = 'data:imge/jpeg;base64,' + res.frontImg;
				this.sfz2 = 'data:imge/jpeg;base64,' + res.backImg;


				this.$nextTick(async () => {


					let base64 = await this.saveImage('sfz', '');




					let res3 = await this.$api.base64Idcard({
						'data': [base64.slice(22)]
					});

					this.img = this.$domain + res3.data;

					this.srcList = [this.img]

					console.log('res3', res3)




					const loading = this.$loading({
						lock: true,
						text: '文件处理中',
						color: "#fff",
						fontSize: 100,
						spinner: 'el-icon-loading',
						background: 'rgba(0, 0, 0, 0.7)'
					});


					loading.close();

					this.sumMoney()

					this.file = res3.data;
					//	this.file_hb = res3.data_hb;

					//	this.wxData.print_hb_file = hbRes.result;
					this.wxData.print_file = this.file

					if (this.$store.state.userinfo) {
						let param = {
							name: Date.parse(new Date()) + '.png',
							url: res3.data,
							//		file_hb_url: hbRes.result,
							print_type: 8
						}
						let res = this.$api.pngSave(param);
						if (res.code == 200) {

						}
					}


					this.dialogVisible = true;
					return false;




				})

			},



			handleClose() {
				this.pics = [];
				this.msg = '身份证正面';
				this.dialogVisible = false;
			},


			async AccountList() {
				let query = {
					limit: 1000,
					page: 1
				}
				let res = await this.$api.AccountList(query);
				this.accList = res.data.rows;
				console.log('acc', this.accList)
			},


			//创建任务
			async saveTask(type) {
				let data = {
					'type': this.wxData.task_type,
					'is_mass': 2,
					'paper_type': this.wxData.paper_type,
					'print_file': this.wxData.print_file,
					'print_hb_file': this.wxData.print_hb_file,
					'print_count': this.wxData.print_count,
					'print_number': this.wxData.print_number,
					'user_data': this.ids,
					'money_type': this.wxData.money_type,
					'print_ctype': this.wxData.print_ctype,
					'print_type': this.wxData.print_type
				}
				console.log('任务参数', data);

				let res = await this.$api.saveTask(data);

				if (res.code == 200) {
					console.log('任务', res)
					let param = {
						'id': res.id,
						'type': type + 1
					}
					this.orderPay(param, res.order_number)
				} else {
					this.$message.error(res.msg)
				}



			},


			//订单支付
			async orderPay(data, order_number) {
				let res = await this.$api.orderPay(data);
				console.log('订单', res)
				if (res.code == 200) {
					this.$message.success(res.msg)

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': order_number,
					}
					this.OrderDetail(param)

				} else {
					this.$message.error(res.msg)
				}
			},



			async sumMoney() {

				/* 	try {
				 */
				//this.wxData.print_count = this.pics.length

				this.sumMoneyData.number = this.wxData.print_number;
				this.sumMoneyData.page = this.wxData.print_count;
				this.sumMoneyData.type = this.wxData.print_type;
				this.sumMoneyData.ctype = this.wxData.print_ctype;
				this.sumMoneyData.paper_type = this.wxData.paper_type;

				if (this.wxData.print_ctype == 2) { //双页打印
					this.sumMoneyData.page = Math.ceil(this.wxData.print_count / 2);
				}

				let res = null;
				if (this.$store.state.userinfo) {
					res = await this.$api.sumMoney(this.sumMoneyData);
				} else {
					res = await this.$api.yksumMoney(this.sumMoneyData);
				}
				this.price = res.data
				this.wxData.money_type = res.name;

			},


			async ykpay() {
				this.dialogVisible = false;
				this.wxData.type = 2; //打印支付
				let res = await this.$api.ykscanPay(this.wxData);
				this.wxData.task_number = res.data.task_number;
				this.code = res.data.code_url;
				console.log('code', this.code)

				this.showc = true;


				this.timer = setInterval(() => {
					this.ykscanPaySuccessful(res.data.order_number, res.data.task_number);
				}, 1000)
			},

			async pay(type, enter = 0) {
				this.dialogVisible = false;
				if (type == 'wx') {
					this.wxData.type = 2; //打印支付
					let res = await this.$api.scanPay(this.wxData);
					
					this.wxData.task_number = res.data.task_number;
					
					this.code = res.data.code_url;
					console.log('code', this.code)

					this.showc = true;


					this.timer = setInterval(() => {
						this.scanPaySuccessful(res.data.order_number, res.data.task_number);
					}, 1000)

				} else {


					this.wxData.ctype = 2; //打印支付
					this.wxData.type = enter; //企业


					//群打印需要先创建任务
					if (this.isGroup) {
						this.saveTask(enter);
						return false;
					}


					console.log('余额支付11', this.wxData)
					let res = await this.$api.balancePayment(this.wxData);

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': res.order_number
					}
					console.log('参数', param)

					this.OrderDetail(param)


				}

			},



			async OrderDetail(data) {






				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {



						this.$refs.print.order(res.data, 'printfile')

					}


				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},


			//打印照片
			print(obj) {


				console.log('照片', obj);
				//	return false;



				let self = this;
				this.loading = true;


				let LODOP = getLodop();
				console.log('打印身份证');



				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}

				LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

				if (localStorage.getItem('model') == '1') {
					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
				}




				LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
				    <img  style="width:510px; height:580px;" src=${obj.print_file}>
				  </div>`);

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							self.accomplish(data);

							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				LODOP.PRINT();
				//	LODOP.PRINT();
			},




			previewPic() {

				this.$refs.print.order(this.wxData, 'previewfile')
				return false;
				//this.previewLoading = true;

				//let self = this;





				let obj = JSON.parse(JSON.stringify(this.wxData));



				console.log('obj', obj);


				console.log('wd', this.wxData);

				let hb = obj.print_file.split('.');
				hb = hb[0] + '_hb.' + hb[1];


				obj.print_hb_file = this.$domain + hb;

				obj.print_file = this.$domain + obj.print_file


				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}

				if (obj.task_type == 5 || obj.task_type == 8) { //身份证直接用图片打印
					let LODOP = getLodop();


					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}


					//	return false;

					if (obj.task_type == 8) {
						LODOP.ADD_PRINT_IMAGE("10%", "15%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
			   		    <img  style="width:510px; height:580px;" src=${obj.print_file}>
			   		  </div>`);

					} else {
						LODOP.ADD_PRINT_IMAGE("10%", "25%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
			   		    <img  style="width:500px; height:630px;" src=${obj.print_file}>
			   		  </div>`);
					}

					LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
					//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
					LODOP.On_Return = function(TaskID, Value) {
						// console.log("TaskID:" + TaskID);
						// console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
							// console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.previewLoading = false;


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};


					LODOP.SET_PREVIEW_WINDOW(0, 3, 0, 1200, 800, "");
					LODOP.PREVIEW();



					return false;
				}



			},







			printFile(obj) {
				console.log('开始打印')
				let LODOP = getLodop();

				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}



				this.loading = true;
				console.log('aaa', this.loading)
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");
				//	LODOP.SET_PRINTER_INDEXA(0);

				LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);

				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);

					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');
						//console.log('打印A3纸张')

					} else { //a4纸张
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
						//console.log('打印A4纸张')
					}


				}

				console.log(2233, this.demoDownloadPDF(obj.print_file))

				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(obj.print_file));
				if (obj.print_ctype == 2) { //双面打印
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				}

				LODOP.SET_PRINT_COPIES(1); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						console.log("每次轮询的状态：" + self.printStatus);
						console.log('轮询bb', self.loading)
						if (self.printStatus != 1) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();
							self.pics = [];
							self.msg = '身份证正面';
							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				LODOP.PRINT();
				//	LODOP.PRINT();
				let data = {
					'task_number': obj.task_number,
					'device_number': localStorage.getItem('device')
				};
				this.accomplish(data);

				return;


			},


			async scanPaySuccessful(order, task) {



				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				let that = this;
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.showc = false;


					let data = {
						'print_file': that.$domain + that.wxData.print_file,
						'print_hb_file': that.$domain + that.wxData.print_hb_file,
						'paper_type': that.wxData.paper_type, //黑白
						'print_ctype': that.wxData.print_ctype, //单双面
						'print_number': that.wxData.print_number, //数量
						'task_number': task
					}

				
					clearTimeout(this.timer)
					
					
					
					this.showc = false;
					this.$refs.print.order(this.wxData, 'printfile')
					return false;
				}
			},


			//游客支付状态
			async ykscanPaySuccessful(order, task) {
				let res = await this.$api.ykscanPaySuccessful({
					'order_number': order
				});

				let that = this;
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);

					this.showc = false;
					this.$refs.print.order(this.wxData, 'printfile')
					return false;

				}
			},



			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
				//	this.loading = false;
				//this.TaskList();
			},


			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},

			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.$router.push('/');
			},

			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},







			base64ImgtoFile(dataurl, filename = 'file') {
				let arr = dataurl.split(',')
				let mime = arr[0].match(/:(.*?);/)[1]
				let suffix = mime.split('/')[1]
				let bstr = atob(arr[1])
				let n = bstr.length
				let u8arr = new Uint8Array(n)
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n)
				}
				return new File([u8arr], `${filename}.${suffix}`, {
					type: mime
				})
			},





			kuang() {
				let data = {
					"camidx": "0", // 摄像头索引，0：主头；1：副头
					"open": "1" // 状态，0：关；1：开
				};
				axios.post("http://127.0.0.1:38088/dvideo=cameradeskew", JSON.stringify(data)).then((res) => {
					console.log(res)
				})
			},



			dataURLToBlob(dataurl) {
				let arr = dataurl.split(',');
				let mime = arr[0].match(/:(.*?);/)[1];
				let bstr = atob(arr[1]);
				let n = bstr.length;
				let u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}


				return new Blob([u8arr], {
					type: mime
				});
			},


			//点击方法
			saveImage(divText, imgText) {
				return new Promise(resolve => {

					console.log(divText, imgText);

					let canvasID = this.$refs[divText];
					let that = this;
					let a = document.createElement('a');
					html2canvas(canvasID, {
						useCORS: true
					}).then(canvas => {
						let dom = document.body.appendChild(canvas);
						dom.style.display = 'none';
						a.style.display = 'none';
						document.body.removeChild(dom);



						let blob = that.dataURLToBlob(dom.toDataURL('image/png'));

						resolve(dom.toDataURL('image/png'));


						/* 	a.setAttribute('href', URL.createObjectURL(blob));
						//这块是保存图片操作  可以设置保存的图片的信息
						a.setAttribute('download', imgText + '.png');
						document.body.appendChild(a);
						a.click();
						URL.revokeObjectURL(blob);
						document.body.removeChild(a);
 */

					})
				})


			},



		}
	}
</script>

<style scoped lang='scss'>
	.con {
		display: flex;
		justify-content: space-between;
		height: 600px;
		padding-top: 50px;
		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;

		.title {
			font-size: 30px;
			text-align: center;
			margin-top: 10px;
			color: #FFF566;
		}

		.box1 {
			flex: 2;

			img {
				display: block;
				margin: 20px auto 0px;
				width: 300px;
				
			}
		}


		.box2 {
			flex: 1;

			.btn {
				width: 70%;
				height: 60px;
				line-height: 60px;
				background-color: #95DE64;
				text-align: center;
				margin: 0 auto;
				margin-top: 100px;
				font-size: 30px;
				color: #fff;
				cursor: pointer;

			}
		}

	}

	.pics {
		width: 100%;
		height: 150px;

		box-shadow: 1px 1px 10px #ccc inset;
		margin-top: 15px;

		img {
			display: block;
			float: left;
			height: 130px;
			width: 100px;
			margin: 10px;
		}
	}

	.dialogCon {

		/* 	text-align: center; */
		width: 100%;

		img {
			width: 200px;
			margin: 0 auto;
			display: block;

		}

		.btns {
			margin-top: 20px;
		}

	}
</style>

<style>
	.el-loading-spinner .el-loading-text {
		color: #fff !important;
		font-size: 30px !important;
	}

	.el-loading-spinner .el-icon-loading {
		color: #fff !important;
		font-size: 100px !important;
	}
</style>
